
import { PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Animation from '@/components/animation.vue'
import * as sort from '@/helpers/sort'
import CatalogTechnologyBar from '@/modules/catalog/components/technology/bar/index.vue'
import { DiagramModule } from '@/modules/diagram/store'
import { LandscapeModule } from '@/modules/landscape/store'
import ModelStatusBar from '@/modules/model/components/status-bar/index.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import TagBar from '@/modules/tag/components/bar/index.vue'
import TagGroupBar from '@/modules/tag/components/group/bar.vue'
import { TagModule } from '@/modules/tag/store'
import TeamBar from '@/modules/team/components/bar/index.vue'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { EditorModule } from '../../store'
import Focused from './focused.vue'
import Hidden from './hidden.vue'

interface ITab {
  active: boolean
  click: () => void
  icon: string
  iconHover?: string
  title: string
}

@Component({
  components: {
    Animation,
    CatalogTechnologyBar,
    Focused,
    Hidden,
    ModelStatusBar,
    TagBar,
    TagGroupBar,
    TeamBar
  },
  name: 'EditorOverlayBar'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly tagGroupsBarRef!: TagGroupBar

  @Prop() readonly permission!: PermissionType

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get overlayTab () {
    return this.$queryValue('overlay_tab')
  }

  get overlayGroupId () {
    return this.$queryValue('overlay_group')
  }

  get overlayIdsHidden () {
    return this.$queryArray('overlay_hide')
  }

  get overlayIdsFocused () {
    return this.$queryArray('overlay_focus')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get tagGroups () {
    return Object.values(this.tagModule.tagGroups).sort(sort.index)
  }

  get height () {
    if (this.overlayTab === 'tags' && this.overlayGroupId) {
      return 88
    } else if (this.overlayTab === 'tags') {
      return 40
    } else if (this.overlayTab === 'technology' && this.overlayGroupId) {
      return 52
    } else if (this.overlayTab === 'status' && this.overlayGroupId) {
      return 52
    } else if (this.overlayTab === 'teams' && this.overlayGroupId) {
      return 52
    } else {
      return 4
    }
  }

  get tabs () {
    const tabs: ITab[] = [
      {
        active: this.overlayTab === 'tags',
        click: () => {
          if (this.overlayTab === 'tags') {
            if (this.overlayGroupId) {
              this.$replaceQuery({
                overlay_focus: undefined,
                overlay_group: undefined,
                overlay_pin: undefined,
                overlay_tab: 'tags'
              })
            } else {
              this.$replaceQuery({
                overlay_focus: undefined,
                overlay_group: this.editorModule.overlayBarTagGroups[this.currentLandscape.id] || this.tagGroups[0]?.handleId,
                overlay_pin: undefined,
                overlay_tab: 'tags'
              })
            }
          } else {
            this.$replaceQuery({
              overlay_focus: undefined,
              overlay_group: this.editorModule.overlayBarTagGroups[this.currentLandscape.id] || this.tagGroups[0]?.handleId,
              overlay_pin: undefined,
              overlay_tab: 'tags'
            })
          }

          if (this.currentDiagram && this.overlayTab !== 'tags') {
            analytics.editorOverlayBarTags.track(this, {
              diagramType: this.currentDiagram.type,
              landscapeId: [this.currentLandscape.id],
              organizationId: [this.currentLandscape.organizationId]
            })
          }
        },
        icon: '$custom-duotone-tags',
        iconHover: this.overlayTab === 'tags' ? this.overlayGroupId ? '$fas-chevron-down' : '$fas-chevron-up' : undefined,
        title: 'Tags'
      },
      {
        active: this.overlayTab === 'technology',
        click: () => {
          if (this.overlayTab === 'technology') {
            if (this.overlayGroupId) {
              this.$replaceQuery({
                overlay_focus: undefined,
                overlay_group: undefined,
                overlay_pin: undefined,
                overlay_tab: 'technology'
              })
            } else {
              this.$replaceQuery({
                overlay_focus: undefined,
                overlay_group: 'all',
                overlay_pin: undefined,
                overlay_tab: 'technology'
              })
            }
          } else {
            this.$replaceQuery({
              overlay_focus: undefined,
              overlay_group: 'all',
              overlay_pin: undefined,
              overlay_tab: 'technology'
            })
          }

          if (this.currentDiagram && this.overlayTab !== 'technology') {
            analytics.editorOverlayBarTechnology.track(this, {
              diagramType: this.currentDiagram.type,
              landscapeId: [this.currentLandscape.id],
              organizationId: [this.currentLandscape.organizationId]
            })
          }
        },
        icon: '$custom-duotone-code',
        iconHover: this.overlayTab === 'technology' ? this.overlayGroupId ? '$fas-chevron-down' : '$fas-chevron-up' : undefined,
        title: 'Technology'
      },
      {
        active: this.overlayTab === 'status',
        click: () => {
          if (this.overlayTab === 'status') {
            if (this.overlayGroupId) {
              this.$replaceQuery({
                overlay_focus: undefined,
                overlay_group: undefined,
                overlay_pin: undefined,
                overlay_tab: 'status'
              })
            } else {
              this.$replaceQuery({
                overlay_focus: undefined,
                overlay_group: 'all',
                overlay_pin: undefined,
                overlay_tab: 'status'
              })
            }
          } else {
            this.$replaceQuery({
              overlay_focus: undefined,
              overlay_group: 'all',
              overlay_pin: undefined,
              overlay_tab: 'status'
            })
          }

          if (this.currentDiagram && this.overlayTab !== 'status') {
            analytics.editorOverlayBarStatus.track(this, {
              diagramType: this.currentDiagram.type,
              landscapeId: [this.currentLandscape.id],
              organizationId: [this.currentLandscape.organizationId]
            })
          }
        },
        icon: '$fas-spinner',
        iconHover: this.overlayTab === 'status' ? this.overlayGroupId ? '$fas-chevron-down' : '$fas-chevron-up' : undefined,
        title: 'Status'
      }
    ]

    if (!this.currentShareLink) {
      tabs.push({
        active: this.overlayTab === 'teams',
        click: () => {
          if (this.overlayTab === 'teams') {
            if (this.overlayGroupId) {
              this.$replaceQuery({
                overlay_focus: undefined,
                overlay_group: undefined,
                overlay_pin: undefined,
                overlay_tab: 'teams'
              })
            } else {
              this.$replaceQuery({
                overlay_focus: undefined,
                overlay_group: 'all',
                overlay_pin: undefined,
                overlay_tab: 'teams'
              })
            }
          } else {
            this.$replaceQuery({
              overlay_focus: undefined,
              overlay_group: 'all',
              overlay_pin: undefined,
              overlay_tab: 'teams'
            })
          }

          if (this.currentDiagram && this.overlayTab !== 'teams') {
            analytics.editorOverlayBarTeam.track(this, {
              diagramType: this.currentDiagram.type,
              landscapeId: [this.currentLandscape.id],
              organizationId: [this.currentLandscape.organizationId]
            })
          }
        },
        icon: '$fas-users',
        iconHover: this.overlayTab === 'teams' ? this.overlayGroupId ? '$fas-chevron-down' : '$fas-chevron-up' : undefined,
        title: 'Teams'
      })
    }

    return tabs
  }

  resize () {
    this.tagGroupsBarRef?.resize()
  }
}
