
import { PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { DiagramModule } from '@/modules/diagram/store'
import DescriptionEditor from '@/modules/editor/components/description-editor.vue'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { SocketModule } from '@/modules/socket/store'
import { UserModule } from '@/modules/user/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'

const EDITING_INTERVAL = 10 * 1000

@Component({
  components: {
    DescriptionEditor,
    Dialog
  },
  name: 'EditorDescriptionDialog'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  socketModule = getModule(SocketModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  editingNotificationTimer?: number

  @Prop() readonly permission!: PermissionType

  get currentLandscapeId () {
    return this.$params.landscapedId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.currentShareLink?.versionId || this.$params.versionId || 'latest'
  }

  get descriptionDialog () {
    return this.$queryValue('description_dialog')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.descriptionDialog)
  }

  get currentUserTyping () {
    return Object.values(this.editorModule.typing).find(o => o.id === this.currentDiagram?.id && o.userId !== this.userModule.user?.id)
  }

  opened () {
    if (this.currentDiagram) {
      analytics.editorDescriptionDialog.track(this, {
        editorDocumentationObjectType: this.currentDiagram.type,
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  editNotificationStart (id: string) {
    const landscapeId = this.currentLandscape?.id
    if (landscapeId) {
      this.editorModule.editorTypingUpdate({
        landscapeId,
        typing: {
          id
        }
      })
      clearInterval(this.editingNotificationTimer)
      this.editingNotificationTimer = window.setInterval(() => {
        this.editorModule.editorTypingUpdate({
          landscapeId,
          typing: {
            id
          }
        })
      }, EDITING_INTERVAL)
    }
  }

  editNotificationEnd () {
    const landscapeId = this.currentLandscape?.id
    if (landscapeId) {
      clearInterval(this.editingNotificationTimer)
      this.editorModule.editorTypingUpdate({
        landscapeId,
        typing: {}
      })
    }
  }
}
