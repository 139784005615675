
import { TAG_COLOR_BACKGROUND, TAG_COLOR_INACTIVE } from '@icepanel/app-canvas'
import { ModelObjectTechnology, ModelStatus, modelStatuses, Tag, TagGroup, Team } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { iconUrlForTheme } from '@/helpers/theme'
import * as catalogAnalytics from '@/modules/catalog/helpers/analytics'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import * as modelAnalytics from '@/modules/model/helpers/analytics'
import statusIcons from '@/modules/model/helpers/status-icon'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import TagChip from '@/modules/tag/components/tag.vue'
import * as tagAnalytics from '@/modules/tag/helpers/analytics'
import * as icons from '@/modules/tag/helpers/icons'
import { TagModule } from '@/modules/tag/store'
import * as teamAnalytics from '@/modules/team/helpers/analytics'
import { TeamModule } from '@/modules/team/store'
import { VersionModule } from '@/modules/version/store'

@Component({
  components: {
    TagChip
  },
  name: 'EditorOverlayHidden'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get overlayIdsHidden () {
    return this.$queryArray('overlay_hide')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get hiddenTags () {
    return this.overlayIdsHidden
      .map(o => Object.values(this.tagModule.tags).find(t => t.handleId === o))
      .filter((o): o is Tag => !!o)
      .map(o => ({
        background: TAG_COLOR_BACKGROUND[o.color],
        color: TAG_COLOR_INACTIVE[o.color],
        group: this.tagModule.tagGroups[o.groupId],
        icon: icons.tagGroups[this.tagModule.tagGroups[o.groupId].icon],
        id: o.handleId,
        tag: o
      }))
  }

  get hiddenTechnology () {
    const technologies = this.modelModule.technologies
    return this.overlayIdsHidden
      .map(o => technologies[o])
      .filter((o): o is ModelObjectTechnology => !!o)
      .map(o => ({
        background: TAG_COLOR_BACKGROUND[o.color],
        color: TAG_COLOR_INACTIVE[o.color],
        id: o.id,
        image: iconUrlForTheme(o),
        technology: o
      }))
  }

  get hiddenStatus () {
    return this.overlayIdsHidden
      .map(o => Object.values(modelStatuses).find(t => o === t.id))
      .filter((o): o is ModelStatus => !!o)
      .map(o => ({
        background: TAG_COLOR_BACKGROUND[o.color],
        color: TAG_COLOR_INACTIVE[o.color],
        icon: statusIcons[o.type],
        id: o.id,
        status: o
      }))
  }

  get hiddenTeam () {
    return this.overlayIdsHidden
      .map(o => this.teamModule.teams.find(t => o === t.id))
      .filter((o): o is Team => !!o)
      .map(o => {
        return {
          background: TAG_COLOR_BACKGROUND[o.color],
          color: TAG_COLOR_INACTIVE[o.color],
          icon: '$fas-users',
          id: o.id,
          team: o
        }
      })
  }

  get hiddenCount () {
    return this.hiddenTags.length + this.hiddenTechnology.length + this.hiddenStatus.length + this.hiddenTeam.length
  }

  setUnhidePreview (id: string, enabled: boolean) {
    this.editorModule.setUnhidePreview({ enabled, id })
  }

  clickTag (tag: Tag, tagGroup: TagGroup) {
    this.$replaceQuery({
      overlay_hide: this.$removeQueryArray(tag.handleId)
    })

    tagAnalytics.tagUnhide.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      tagColor: tag.color,
      tagGroupIcon: tagGroup.icon,
      tagGroupName: tagGroup.name,
      tagName: tag.name
    })
  }

  clickTechnology (technology: ModelObjectTechnology) {
    this.$replaceQuery({
      overlay_hide: this.$removeQueryArray(technology.id)
    })

    catalogAnalytics.catalogTechnologyUnhide.track(this, {
      catalogTechnologyColor: technology.color,
      catalogTechnologyName: technology.name,
      catalogTechnologyNameShort: technology.nameShort,
      catalogTechnologyProvider: technology.provider,
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  clickStatus (status: ModelStatus) {
    this.$replaceQuery({
      overlay_hide: this.$removeQueryArray(status.id)
    })

    modelAnalytics.modelStatusUnhide.track(this, {
      landscapeId: [this.currentLandscape.id],
      modelStatus: status.type,
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  clickTeam (team: Team) {
    this.$replaceQuery({
      overlay_hide: this.$removeQueryArray(team.id)
    })

    teamAnalytics.teamUnhide.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }
}
