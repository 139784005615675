
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { DomainModule } from '@/modules/domain/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { RouteModule } from '@/modules/route/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { DiagramModule } from '../../diagram/store'
import * as analytics from '../helpers/analytics'

@Component({
  name: 'EditorBackButton'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  routeModule = getModule(RouteModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get currentLandscapeId () {
    return this.currentVersion?.landscapeId || this.$params.landscapeId
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape.organizationId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId)
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get lastRoute () {
    return this.routeModule.history[this.routeModule.historyCursor + 1]
  }

  get text () {
    if (this.lastRoute?.name?.endsWith('-diagram')) {
      const diagram = Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.lastRoute.query.diagram)
      const model = Object.values(this.modelModule.objects).find(o => o.handleId === this.lastRoute.query.model)
      const domain = model ? this.domainModule.domains[model.domainId] : undefined
      if (diagram && model && domain && model.type === 'root') {
        return [domain.name, diagram.name] as const
      } else if (diagram && model && model.type !== 'root') {
        const modelName = model.name || `${model.type.slice(0, 1).toUpperCase()}${model.type.slice(1)}`
        return [modelName, diagram.name] as const
      }
    } else if (this.lastRoute?.name?.endsWith('flows')) {
      return 'Flows'
    } else if (this.lastRoute?.name?.endsWith('diagrams')) {
      return 'Diagrams'
    } else if (this.lastRoute?.name?.endsWith('model-objects')) {
      return 'Model objects'
    } else if (this.lastRoute?.name?.endsWith('model-dependencies')) {
      return 'Depedencies'
    } else if (this.lastRoute?.name?.endsWith('history')) {
      return 'History'
    } else {
      return 'Overview'
    }
  }

  click () {
    const lastRoute = this.lastRoute
    if (lastRoute?.name && (this.currentShareLink || lastRoute.params.landscapeId === this.currentLandscapeId)) {
      this.routeModule.setHistoryCursor(1)

      this.$router.replace({
        name: lastRoute.name,
        params: lastRoute.params,
        query: lastRoute.query
      })
    } else if (this.currentShareLink) {
      this.$router.push({
        name: 'share-landscape',
        params: {
          shortId: this.currentShareLink.shortId
        }
      })
    } else if (this.currentLandscapeId && this.currentVersionId) {
      this.$router.push({
        name: 'landscape',
        params: {
          landscapeId: this.currentLandscapeId,
          versionId: this.currentVersionId
        }
      })
    } else if (this.currentOrganizationId) {
      this.$router.push({
        name: 'landscapes',
        params: {
          organizationId: this.currentOrganizationId
        }
      })
    } else {
      this.$router.push({
        name: 'organizations'
      })
    }

    analytics.editorBackButton.track(this, {
      diagramType: this.$routeName?.endsWith('-diagram') ? this.currentDiagram?.type : undefined,
      landscapeId: [this.currentLandscapeId],
      organizationId: [this.currentOrganizationId]
    })
  }
}
