
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { DomainModule } from '@/modules/domain/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { RouteModule } from '@/modules/route/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { DiagramModule } from '../../diagram/store'
import * as analytics from '../helpers/analytics'

@Component({
  name: 'EditorForwardButton'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  routeModule = getModule(RouteModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get currentLandscapeId () {
    return this.currentVersion?.landscapeId || this.$params.landscapeId
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape.organizationId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId)
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get nextRoute () {
    return this.routeModule.history[this.routeModule.historyCursor - 1]
  }

  get text () {
    if (this.nextRoute) {
      const diagram = Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.nextRoute.query.diagram)
      const model = Object.values(this.modelModule.objects).find(o => o.handleId === this.nextRoute.query.model)
      const domain = model ? this.domainModule.domains[model.domainId] : undefined
      if (diagram && model && domain && model.type === 'root') {
        return [domain.name, diagram.name] as const
      } else if (diagram && model && model.type !== 'root') {
        const modelName = model.name || `${model.type.slice(0, 1).toUpperCase()}${model.type.slice(1)}`
        return [modelName, diagram.name] as const
      }
    }
  }

  click () {
    const nextRoute = this.nextRoute
    if (nextRoute?.name && (this.currentShareLink || nextRoute.params.landscapeId === this.currentLandscapeId)) {
      this.routeModule.setHistoryCursor(-1)

      this.$router.replace({
        name: nextRoute.name,
        params: nextRoute.params,
        query: nextRoute.query
      })
    }

    analytics.editorForwardButton.track(this, {
      diagramType: this.$routeName?.endsWith('-diagram') ? this.currentDiagram?.type : undefined,
      landscapeId: [this.currentLandscapeId],
      organizationId: [this.currentOrganizationId]
    })
  }
}
